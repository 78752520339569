import React from "react";
import { bool, string } from "prop-types";
import CtaPrimary from "../Buttons/primary-cta";
import Image from "../ImageQuerys/LargeFeaturesImages";
import Heading from "../../components-v2/Base/HeadingBuilder";

const GatedSellingPoint = (props) => {
  const {
    mainPoint,
    mark,
    subtext,
    ctaTitle,
    ctaTarget,
    imageName,
    imgClass,
    withCta,
  } = props;
  return (
    <div className="gated-selling">
      <Heading
        level={2}
        injectionType={1}
        className="text-center"
        withBlueSymbol
        symbol={mark}
      >
        {mainPoint}
      </Heading>
      <p className="selling-subtext">{subtext}</p>
      <div className="fake-center">
        {withCta && (
          <CtaPrimary
            ctaTitle={ctaTitle}
            target={ctaTarget}
            style={{ zIndex: 2 }}
          />
        )}
      </div>
      <div style={{ transform: "translateY(-20%)", zIndex: "-1" }}>
        <Image className={`w-full ${imgClass}`} imageName={imageName} />
      </div>
    </div>
  );
};
GatedSellingPoint.propTypes = {
  mainPoint: string,
  mark: string,
  subtext: string,
  ctaTitle: string,
  ctaTarget: string,
  imageName: string,
  imgClass: string,
  withCta: bool,
};

GatedSellingPoint.defaultProps = {
  mainPoint: "",
  mark: ".",
  subtext: "",
  ctaTitle: "Learn more",
  withCta: true,
  ctaTarget:
    "/demo?utm_source=gated_assets&utm_medium=referral&utm_campaign=gated_assets",
  imageName: "",
  imgClass: "",
};

export default GatedSellingPoint;
